// @ts-check
import { Calculation } from '@/types/mapping';
import { ElseIfExpression, IfElseExpression } from '@/types/settings';
import { getRandomUUID } from '@/utils/functional';
import { Column } from '@tableau/extensions-api-types';
import { ColDef } from 'ag-grid-community';
import { removeAgg } from '../utils/tableau';
import { getField } from './mapping/utils';

export function compileCalculation(column: Calculation, columns: Array<Column | Calculation>) {
  try {
    let expression = parseCalculation(column, columns);

    return new Function('data', expression ?? "return 'error'");
  } catch (error) {
    console.log('error', `${error} in calculation ${column.fieldName}`);
  }
}

/**
 * It should be a Calculation if it comes from SuperTables.
 * Else It should contain a calculation for error checking
 */
export function parseCalculation(column: Calculation | { calculation: string }, columns: Array<Column | Calculation>) {
  const calculation = column.calculation;
  if (!calculation) return null;

  const [isIfElse, variable] = getCalculation(calculation);
  if (!isIfElse || !variable) {
    // normal calculation
    return 'return ' + _parseCalculation(column, columns);
  }

  if (typeof variable === 'object') {
    const { ifExpression, thenExpression, elseExpression, elseIfExpressions } = variable;
    let ifElseStatement = '';

    const ifFields = _parseCalculation({ ...column, calculation: ifExpression }, columns);
    const thenFields = _parseCalculation({ ...column, calculation: thenExpression }, columns);
    ifElseStatement += `if (${ifFields}) return ${thenFields};`;

    elseIfExpressions.forEach((elseIfExpression: ElseIfExpression) => {
      const ifFields = _parseCalculation({ ...column, calculation: elseIfExpression.ifExpression }, columns);
      const thenFields = _parseCalculation({ ...column, calculation: elseIfExpression.thenExpression }, columns);
      ifElseStatement += `else if (${ifFields}) return ${thenFields};`;
    });

    const elseFields = _parseCalculation({ ...column, calculation: elseExpression }, columns);
    ifElseStatement += `else return ${elseFields};`;

    return ifElseStatement;
  }

  return null;
}

function removeABS(calculation: string = ''): string {
  if (calculation.includes('ABS(')) {
    let startIndex = calculation.indexOf('ABS(');
    let endIndex = startIndex + 3;
    calculation = calculation.substring(0, startIndex) + calculation.substring(endIndex + 1);

    // // Find the matching closing parenthesis
    let openBracketCount = 1;
    for (let i = startIndex; i < calculation.length; i++) {
      if (calculation[i] === '(') {
        openBracketCount++;
      }

      if (calculation[i] === ')') {
        openBracketCount--;
      }

      if (openBracketCount === 0 && calculation[i] === ')') {
        calculation = calculation.substring(0, i) + calculation.substring(i + 1);
        break;
      }
    }
    if (openBracketCount !== 0) throw new Error(`Unclosed parenthesis in Formula`);
  }

  if (calculation.includes('ABS(')) return removeABS(calculation);

  return calculation;
}

export function _parseCalculation(column: Calculation | { calculation: string }, columns: Array<Column | Calculation>) {
  let calculation = column.calculation;
  if (/ABS\(/.test(column.calculation)) {
    calculation = removeABS(column.calculation);
  }

  let usedFields = getUsedFields(calculation);

  let remainder = column.calculation;

  let parts: Array<string> = [];

  for (let field of usedFields) {
    // this is a number so we can just leave it be...
    if (Number.isNaN(Number(field)) === false) continue;

    // if it is a quoted text part return it
    if (field.startsWith(`"`)) continue;
    if (field.startsWith(`'`)) continue;

    if (/ABS\(/.test(field)) {
      field = removeABS(field);
    }

    let fieldColumn = columns.find(
      (col) =>
        col.fieldName === field ||
        col.fieldName === removeAgg(field) ||
        `COL([${col.fieldName}])` === field ||
        `COL([${col.fieldName}])` === removeAgg(field) || // calculations with operators use COL([field-name]) instead of (field-name)
        col.index === field // calculation fields can be part of FrontEnd Formulas
    );
    if (!fieldColumn) {
      throw new Error(`Could not find column "${field}"`);
    }

    // NOTE: This is so that if else calculations can do string comparisons
    // if (fieldColumn.dataType !== 'float' && fieldColumn.dataType !== 'int') {
    //   throw new Error(`Result should be numeric but contains a non-numeric field "${field}"`);
    // }

    let accessor = `data[${safeQuote(getField(fieldColumn))}]`;
    let index = remainder.indexOf(field);
    index += field.length;
    let before = remainder.substring(0, index);
    remainder = remainder.substring(index);
    let result = before.replace(field, accessor);
    parts.push(result);
  }

  parts.push(remainder);
  let calcString = parts.join('');
  calcString = parts.join('').replace(/\bABS\b/g, 'Math.abs');

  return calcString;
}

/**
 * Gets the fields used by a calculation.
 *
 * Essentially this function splits a string on `+ - / *` but it is aware of parentheses
 * so `(SUM(test) / SUM(other)) * 2` will return `['SUM(test)', 'SUM(other)', '2']`,
 * note how the wrapping parenths were ignored because they contained a division sign.
 */
export function getUsedFields(calculation: string): Array<string> {
  let { result } = _getUsedFields(calculation);

  return result.map((x) => x);
}

/**
 * Internal version of getUsedFields that returns an object and has extra arguments
 */
function _getUsedFields(
  calculation: string,
  startIndex = 0,
  previous = ''
): { result: Array<string>; endIndex: number } {
  let fields = [];
  let part = '';
  let quoteOpened;

  // We start processing the calculation by looping over the calculation
  // one letter at a time starting at the given startIndex.
  // letters will be added to the `part` unless it is a special case.
  for (let index = startIndex; index < calculation.length; index++) {
    let letter = calculation[index];

    if ((letter === `"` || letter === `'`) && !previous) {
      if (!quoteOpened) {
        quoteOpened = letter;
        continue;
      } else if (quoteOpened === letter) {
        part = safeQuote(part.trim());
        if (part) {
          fields.push(part);
        }
        part = '';
        quoteOpened = undefined;
      }
    }
    // if we are in a quote we need to save all text
    if (quoteOpened) {
      part += letter;
      continue;
    }

    switch (letter) {
      case '+':
      case '-':
      case '*':
      case '/':
      case '<':
      case '>':
      case '!':
      case '=':
      case '&':
      case '|':
        // we expect to be inside the parenths of a field
        // but we found an operator, unsure how to handle this...
        if (previous) {
          part += letter;
          break;
          // throw new Error(`Unexpected operator in field at position ${index}, ${previous}`);
        }
        // if we encounter an operator we 'seal' the current part
        // by trimming it and adding it to the list of fields.
        part = part.trim();
        if (part) {
          fields.push(part);
        }
        part = '';
        break;

      case '(': {
        // if the current part is just some whitespace we should already
        // get rid of it as not to confuse the recursive call...
        part = part.trim() === '' ? '' : part;
        // if we encounter an opening parenth we re-start parsing the
        // calculation at the current index + 1 to get the fields used
        // in this parenth-enclosed part of the calculation.
        let { result, endIndex } = _getUsedFields(calculation, index + 1, part);
        // the current part was just handled by the getUsedFields call and
        // is incorporated in the result so we can discard it now.
        part = '';
        // move our cursor forward to the end of the parenth-enclosed part
        index = endIndex;
        if (previous) {
          // @todo: this is to fix issues with deeply nested calcs
          // but is clearly quite fragile and I can't even explain
          // why it is necessary/why it works...
          if (result.length > 1) {
            throw new Error('If you see this error, you win!');
          }
          part = result[0];
          break;
        }
        // add the fields of the parenth-enclosed part to our list of fields.
        fields.push(...result);
        break;
      }

      case ')': {
        if (startIndex === 0) {
          throw new Error(`Unexpected closing parenthesis at position ${index}`);
        }
        // if we have a previous like `SUM` and a current part like `test`
        // we wanna join them like `SUM(test)`
        // @note we didn't trim part uet because we want to maintain
        // whitespace, this is unlikely to make a real world difference though
        if (previous && part) {
          part = `${previous}(${part})`;
        }
        // add the current part to the list of fields.
        part = part.trim();
        if (part) {
          fields.push(part);
        }
        // we are in a parenth-enclosed part so we also return endIndex.
        return { result: fields, endIndex: index };
      }

      default:
        part += letter;
    }
  }

  // We reached the end of the calculation but we are in a parenth-enclosed part
  // this indicates unbalanced parentheses!
  if (startIndex !== 0) {
    throw new Error(`Parenthesis at position ${startIndex - 1} was never closed`);
  }

  if (quoteOpened) {
    throw new Error(`Quote (${quoteOpened}) was never closed`);
  }

  // add the final part to the list of fields.
  part = part.trim();
  if (part) {
    fields.push(part);
  }

  return { result: fields, endIndex: calculation.length };
}

/**
 * This turns a string containing fields wrapped with `<>` into a function that
 * will take an object containing row data and return the string with that
 * data interpolated into it.
 */
export function createTextInterpolator(text: string, columns: Array<Column | Calculation | ColDef>) {
  if (!text) return;
  let result = 'return ""';
  let currentIndex = 0;
  let readingColumnName = true;
  let chunk: ReturnType<typeof readUntil>;
  do {
    readingColumnName = !readingColumnName;
    chunk = readUntil(readingColumnName ? '>' : '<', text, currentIndex);

    if (readingColumnName) {
      if (chunk.found === false) {
        throw new Error(`Unclosed angle bracket at position ${currentIndex}`);
      }

      let column = columns.find(
        (col) =>
          col.fieldName === chunk.part ||
          removeAgg(col.fieldName) === chunk.part ||
          col.field === chunk.part ||
          removeAgg(col.field) === chunk.part
      );

      if (!column) {
        throw new Error(`Could not find column "${chunk.part}"`);
      }

      result += ` + data[${safeQuote(removeAgg(getField(column)))}]`;
    } else {
      result += ` + ${safeQuote(chunk.part)}`;
    }

    currentIndex = chunk.endIndex + 1;
  } while (currentIndex < text.length);

  return new Function('data', result);
}

function readUntil(letter: string, text: string, startIndex = 0) {
  let part = '';
  for (let index = startIndex; index < text.length; index++) {
    if (text[index] === letter) return { found: true, part, endIndex: index };
    part += text[index];
  }
  return { found: false, part, endIndex: text.length };
}

/**
 * safeQuote quotes the text with `"` characters and escapes those characters
 * from the string so that it is safe to execute as JavaScript
 */
function safeQuote(text: string) {
  return '"' + text?.replace(/\"/g, '\\"') + '"';
}

/**
 * Gets the expressions for if, then, elseif and else.
 * If calculation is not an if/else, then returns the expression as is.

* Boolean is whether the expression is an if/else.
 * null in case expression is not if/else.
 * IfElseExpression is an object containing the if, then, elseif and else expressions.
 */
export const getCalculation = (expression: string): [boolean, null | IfElseExpression] => {
  // separates if, then, elseifs
  const ifElseRegex = /^if{{(.+?)}} then{{(.+?)}}(?: elseif{{(.+?)}} then{{(.+?)}})* else{{(.+?)}} end$/;
  const matches = expression.match(ifElseRegex);

  if (!matches) {
    return [false, null];
  }

  const [, ifExpression, thenExpression, ...elseIfMatches] = matches;

  // if there's no elseif statement, elseIfMatches array would contain elseExpression at the last index
  let elseExpression = elseIfMatches.pop();
  elseExpression = elseExpression ? elseExpression.trim() : '';

  const elseIfRegex = /elseif{{(.+?)}} then{{(.+?)}}/g;
  const elseIfMatchesExpressions = Array.from(expression.matchAll(elseIfRegex));

  let elseIfExpressions = elseIfMatchesExpressions.map((match) => {
    return {
      id: getRandomUUID(),
      ifExpression: match[1].trim(),
      thenExpression: match[2].trim(),
    };
  });

  const ifElseExpression = {
    ifExpression,
    thenExpression,
    elseExpression,
    elseIfExpressions,
  };

  return [true, ifElseExpression];
};

/**
 * Gets the fields used in the if/else expression.
 */
export const getUsedFieldsFromIfElseExpression = (expressions: IfElseExpression): string[] => {
  let usedFields: string[] = [];
  const { ifExpression, thenExpression, elseExpression, elseIfExpressions } = expressions;
  usedFields = getUsedFields(ifExpression) || [];
  usedFields = usedFields.concat(getUsedFields(thenExpression) || []);
  usedFields = usedFields.concat(getUsedFields(elseExpression) || []);
  elseIfExpressions.forEach((elseIfExpression) => {
    const { ifExpression, thenExpression } = elseIfExpression;
    usedFields = usedFields.concat(getUsedFields(ifExpression) || []);
    usedFields = usedFields.concat(getUsedFields(thenExpression) || []);
  });
  return usedFields;
};

export const validateCalculation = (
  column: Calculation | { calculation: string },
  columns: Array<Column | Calculation>
) => {
  try {
    parseCalculation(column, columns);
    return null;
  } catch (error) {
    return 'Error in calculation: ' + error;
  }
};
